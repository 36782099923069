export default [
  {
    text1: `Initiative Haute-Garonne est membre de Initiative France, réseau associatif de financement et d'accompagnement des créateurs et repreneurs d'entreprise.`,
    text2: `Initiative Haute-Garonne a été la première structure toulousaine à croire au projet Selfcity dès septembre 2019 par un prêt d'honneur accordé aux deux associés fondateurs, Chérif MILI et Maxime PAWLAK.`,
    website: "www.initiative-haute-garonne.org",
    email: "contact@initiative-haute-garonne.org",
    imgSrc: "/img/partners/logo_ihg_v2.png",
    imgAlt: "Initiative Haute-Garonne",
  },
  {
    text1: `Réseau Entreprendre est un réseau de chefs d’entreprises bénévoles réunis au sein d’une fédération qui vise la création d’emplois dans les territoires par la création d’entrepreneurs.`,
    text2: `Depuis avril 2020, Chérif MILI, Président de Selfcity est lauréat de Réseau Entreprendre Occitanie. À ce titre, il est soutenu par le Réseau financièrement, mais aussi et surtout par un accompagnement humain pour développer l'entreprise et créer des emplois.`,
    website: "www.reseau-entreprendre.org/occitanie-garonne/",
    email: "occitanie-garonne@reseau-entreprendre.org",
    imgSrc: "/img/partners/logo_reseau_entreprendre_x80.png",
    imgAlt: "Réseau Entreprendre",
  },
  {
    text1: `Société coopérative de financement, IéS (initiatives pour une économie solidaire) est un acteur engagé qui finance les entreprises à impact social et/ou environnemental. IèS est gérée par plus d'une centaine de bénévoles répartis en groupes de travail et comités.`,
    text2: `Depuis avril 2020, IéS soutient Selfcity par un accompagnement par 2 experts membres, plus un prêt participatif.`,
    website: "www.ies.coop",
    email: "permanence@ies.coop",
    imgSrc: "/img/partners/ies.png",
    imgAlt: "IèS",
  },
  {
    text1: `Hébergée au Village By CA 31, la banque de l'innovation vise à être un acteur référent sur le marché des entreprises innovantes. Pour cela un dispositif particulier a été mis en place pour accorder des prêts à des entreprises innovantes qui démarrent.`,
    text2: `En plein confinement, le comité de la banque de l'innovation a accordé un prêt d'investissement à Selfcity.`,
    website: null,
    email: null,
    address: "31 Allée Jules Guesde Toulouse",
    phoneNumber: "05.61.14.50.30",
    imgSrc: "/img/partners/banque_innovation.png",
    imgAlt: "Banque de l'innovation",
  },
];
