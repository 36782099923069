import React from "react";
import Helmet from "react-helmet";

import "../../../styles/index-2020-04.css";
import "../../../styles/activities.css";
import "../../../styles/activity-modal.css";

import FormButton from "../../elements/FormButton";
import ContactFormModal from "../../elements/ContactFormModal";

export default ({
  showModal,
  reference,
  partners,
  onOpenContactFormModal,
  onCloseContactFormModal,
}) => {
  const title = "Selfcity | Partenaires";
  const description =
    "Nos partenaires instituionnels à Selfcity, plombier et chauffagiste à Toulouse.";

  return (
    <main className="style-2020-04 page-partenaires">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <meta
          name="facebook-domain-verification"
          content="1qrddrr0iq21u29flzc4zdmna6dgqf"
        />
      </Helmet>

      {/* {showModal ? (
        <ContactFormModal
          onClose={onCloseContactFormModal}
          reference={reference}
          title="Devenir partenaire"
          label="partenaires"
          hideFields={["contact-postalCode"]}
        />
      ) : null} */}

      <section className="home-section section-intro">
        <h1 className="section-title center">
          Un <span className="dark-green">logement sain</span> dans{" "}
          <br className="hide-big" /> une{" "}
          <span className="dark-green">ville saine</span>
        </h1>
        <div className="section-illustration center">
          <img
            src="/img/page-partenaires/sustainability.png"
            alt="Logement sain"
            width="160px"
          />
        </div>
        <div className="section-body flex">
          <p className="section-text section-text-1">
            Selfcity vise à transformer la maintenance des logements en milieu
            urbain en innovant autour de 3 axes :
          </p>
          <ul className="section-text__list">
            <li>
              <span className="section-text__list__item__bullet">1.</span>
              <span className="section-text__list__item__title">
                Notre impact environnemental
              </span>{" "}
              qu’on réduit significativement notamment par l’utilisation de
              véhicules électriques légers et d'électricité verte ;
            </li>
            <li>
              <span className="section-text__list__item__bullet">2.</span>
              <span className="section-text__list__item__title">
                L’obsession de la qualité de service
              </span>
              , grâce à l'optimisation logistique et à des processus digitalisés
              centrés sur l'expérience client ;
            </li>
            <li>
              <span className="section-text__list__item__bullet">3.</span>
              <span className="section-text__list__item__title">
                Notre responsabilité sociale
              </span>
              , à laquelle on porte une attention particulière avec le choix
              d’être un employeur modèle pour nos techniciens tous embauchés en
              CDI.
            </li>
          </ul>

          <p className="section-text">
            Dans notre démarche, nous sommes soutenus par un certain nombre
            d’acteurs, convaincus par la qualité de nos services et{" "}
            <span className="semi-bold">partageant nos valeurs</span>. Nous les
            présentons ici.
          </p>
        </div>
        {/* <div className="center section-button">
          <FormButton
            text="Devenir partenaire"
            onClick={onOpenContactFormModal}
            label="partenaires"
          />
        </div> */}
      </section>
      <section className="home-section section-partners">
        <h2 className="center section-title">
          Nos partenaires institutionnels
        </h2>
        <div className="partner-cards">
          {partners.map((partner, index) => {
            const {
              text1,
              text2,
              website,
              email,
              address,
              phoneNumber,
              imgSrc,
              imgAlt,
            } = partner;

            return (
              <div key={index} className="partner-card">
                <div className="partner-card__illustration center">
                  <img
                    src={imgSrc}
                    alt={imgAlt}
                    height="80px"
                    className="center"
                  />
                </div>

                <h3 className="partner-card__title">Description :</h3>
                <p
                  className="partner-card__text"
                  dangerouslySetInnerHTML={{ __html: text1 }}
                />

                <h3 className="partner-card__title">Partenariat :</h3>
                <p
                  className="partner-card__text"
                  dangerouslySetInnerHTML={{ __html: text2 }}
                />
                <h3 className="partner-card__title">En savoir plus :</h3>
                <p className="partner-card__text">
                  {website && (
                    <span>
                      Site web :{" "}
                      <a
                        href={"https://" + website}
                        target="no_blank"
                        rel="nofollow"
                      >
                        {website}
                      </a>
                      <br />
                      Mail : <a href={"mailto:" + email}>{email}</a>
                    </span>
                  )}
                  {address && (
                    <span>
                      Adresse : {address}
                      <br />
                      Tél : {phoneNumber}
                    </span>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </main>
  );
};
