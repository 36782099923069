import React, { useState } from "react";
import GAEventHelper from "../../../utils/GAEventHelper";
import ModalHelper from "../../../utils/ModalHelper";
import View from "./View";
import partners from "./data";
import CONSTANTS from "../../../utils/constants";

export default ({ reference }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenContactFormModal = (e, metadata = {}) => {
    e.preventDefault();
    e.stopPropagation();

    ModalHelper.triggerGAClickEvent({
      category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.DEMANDE_DEVIS,
      action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.OPEN,
      event: e,
      metadata,
      options: {
        window,
      },
    });

    setShowModal(true);
  };

  const handleCloseContactFormModal = () => {
    setShowModal(false);
  };
  return (
    <View
      reference={reference}
      partners={partners}
      showModal={showModal}
      onOpenContactFormModal={handleOpenContactFormModal}
      onCloseContactFormModal={handleCloseContactFormModal}
    />
  );
};
