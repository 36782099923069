import React from "react";
import Index from "../components/pages/partenaires";
import Layout from "../layouts/Layout";
import CONSTANTS from "../utils/constants";

export default () => {
  return (
    <Layout
      reference={CONSTANTS.PAGE_REFERENCES.PARTENAIRES}
      hidePhoneNumberBanner={true}
    >
      <Index reference={CONSTANTS.PAGE_REFERENCES.PARTENAIRES} />
    </Layout>
  );
};
