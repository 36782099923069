import GAEventHelper from "./GAEventHelper";

export default (() => {
  const triggerGAClickEvent = ({
    category,
    action,
    event,
    metadata,
    options,
  }) => {
    let { label } = metadata;
    if (!label && event && event.currentTarget) {
      label = event.currentTarget.dataset.label;
    }
    GAEventHelper.triggerClickEvent({
      category,
      action,
      label,
      options,
    });
    return label;
  };

  return { triggerGAClickEvent };
})();
