import React from 'react'
// import JotformEmbed from 'react-jotform-embed'
// import store from 'store2'
import ContactHelper from '../../utils/ContactHelper'
import GAEventHelper from '../../utils/GAEventHelper'
import CONSTANTS from '../../utils/constants'

export default class ContactFormModal extends React.Component {
	formTable = {
		'contact-firstName': 'firstName',
		'contact-lastName': 'lastName',
		'contact-phoneNumber': 'phoneNumber',
		'contact-email': 'email',
		'contact-postalCode': 'postalCode',
		'contact-availability': 'availability',
		'contact-description': 'description',
	}

	constructor() {
		super()
		this.state = {
			form: {
				firstName: '',
				lastName: '',
				phoneNumber: '',
				email: '',
				postalCode: '',
				description: '',
				availability: '',
			},
			loading: true,
			sending: false,
			sendingSuccess: null,
		}
		this.form = React.createRef()
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ loading: false })
		}, 500)
	}

	handleSubmitContactForm = (e) => {
		e.preventDefault()
		if (this.state.sending) {
			return
		}

		const email = this.state.form
		email.description = email.description.replace(/\n/g, '<br />')

		this.setState({ sending: true })

		let promiseName = 'postContactForm'
		// Set Google Analytics Events Options
		const gaOptions = {
			category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.DEMANDE_DEVIS,
			action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.SEND,
			label: this.props.label,
		}
		const reference = this.props.reference
		if (reference === 'plombier-visio') {
			promiseName = 'postFormFromVisio'
		} else if (reference === 'partenaires') {
			promiseName = 'sendPartnerFormEmail'
		}

		GAEventHelper.triggerClickEvent({
			...gaOptions,
			options: {
				window,
			},
		})

		ContactHelper[promiseName](email)
			.then((res) => {
				this.form.current.reset()
				this.setState({ sendingSuccess: true })
			})
			.catch((e) => {
				this.setState({ sendingSuccess: false })
			})
			.then(() => {
				this.setState({ sending: false })
			})
	}

	handleCloseModal = (e) => {
		this.props.onClose()
	}

	handleInputChange = (event) => {
		const target = event.target
		const name = target.name
		const value = target.value
		const form = this.state.form
		form[this.formTable[name]] = value
		this.setState({ form })
	}

	/**
	 * # Props
	 * - title
	 * - reference
	 * - hideFields
	 */
	render() {
		const title = this.props.title || 'Demande de contact'
		const { reference, hideFields = [] } = this.props
		let isPlombierOnline = false
		if (reference === 'plombier-visio') {
			isPlombierOnline = true
		}

		// const formId =
		// 	window.location.host === 'dev.selfcity.fr'
		// 		? 4
		// 		: window.location.host.startsWith('localhost')
		// 		? 5
		// 		: 2

		return (
			<section className="modal">
				<div className="modal-bg" onClick={this.handleCloseModal} />

				<div className="modal-content">
					{this.props.modalContent || (
						<div
							className="modal-content--default"
							style={{ position: 'relative' }}
						>
							<div className="close-button">
								<button onClick={this.handleCloseModal}>
									X
								</button>
							</div>
							{/* <JotformEmbed src={clientRequestJotFormURL} /> */}
							{/* <CognitoForm
								formId={formId}
								accountId="YPoi0rssFE6JjYwGqg5ZyA"
								prefill={{
									metadata: {
										referrer: store.session(
											CONSTANTS.REFERRER_STORAGE_KEY,
										),
										referrerHistory: store.local(
											CONSTANTS.REFERRER_STORAGE_KEY,
										),
									},
								}}
								// css="* { color: red !important }"
							/> */}
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									background: 'white',
									display: this.state.loading
										? 'flex'
										: 'none',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								Chargement . . .
							</div>
							{/* //   <div className="modal-title">
						//     <h3>{title}</h3>
						//   </div>
						//   <div className="modal-body">
						//     <form onSubmit={this.handleSubmitContactForm} ref={this.form}>
						//       <div className="form-field">
						//         <label htmlFor="contact-firstName">Prénom * </label>
						//         <input
						//           type="text"
						//           name="contact-firstName"
						//           id="contact-firstName"
						//           required
						//           onChange={this.handleInputChange}
						//         />
						//       </div>
						//       <div className="form-field">
						//         <label htmlFor="contact-lastName">Nom * </label>
						//         <input
						//           type="text"
						//           name="contact-lastName"
						//           id="contact-lastName"
						//           required
						//           onChange={this.handleInputChange}
						//         />
						//       </div>
						//       {hideFields.indexOf("contact-email") < 0 && (
						//         <div className="form-field">
						//           <label htmlFor="contact-email">Email *</label>
						//           <input
						//             type="email"
						//             name="contact-email"
						//             id="contact-email"
						//             required
						//             onChange={this.handleInputChange}
						//           />
						//         </div>
						//       )}
						//       <div className="form-field">
						//         <label htmlFor="contact-phoneNumber">Téléphone</label>
						//         <input
						//           type="text"
						//           name="contact-phoneNumber"
						//           id="contact-phoneNumber"
						//           onChange={this.handleInputChange}
						//         />
						//       </div>
						//       {isPlombierOnline && (
						//         <div className="form-field">
						//           <label htmlFor="contact-availability">
						//             Disponibilités *
						//           </label>
						//           <input
						//             type="text"
						//             name="contact-availability"
						//             id="contact-availability"
						//             onChange={this.handleInputChange}
						//             required
						//           />
						//         </div>
						//       )}
						//       {hideFields.indexOf("contact-postalCode") < 0 && (
						//         <div className="form-field">
						//           <label htmlFor="contact-postalCode">Code Postal *</label>
						//           <input
						//             type="text"
						//             name="contact-postalCode"
						//             id="contact-postalCode"
						//             onChange={this.handleInputChange}
						//             required
						//           />
						//         </div>
						//       )}

						//       <div className="form-field">
						//         <label htmlFor="contact-description">Description *</label>
						//         <textarea
						//           name="contact-description"
						//           id="contact-description"
						//           rows="6"
						//           required
						//           defaultValue=""
						//           onChange={this.handleInputChange}
						//         />
						//       </div>
						//       <div className="form-field form-submit">
						//         <button
						//           className="close-btn"
						//           onClick={this.handleCloseModal}
						//         >
						//           Fermer
						//         </button>
						//         <div className="submit-div">
						//           <button
						//             type="submit"
						//             className={
						//               (this.state.sending ? "disabled" : "") +
						//               " bg-green white"
						//             }
						//           >
						//             Envoyer
						//           </button>
						//           <img
						//             src="/img/icons/loader.svg"
						//             alt="loader"
						//             height="18px"
						//             className={
						//               "loader " + (this.state.sending ? "" : "hide")
						//             }
						//           />
						//         </div>
						//       </div>
						//       {this.state.sendingSuccess ? (
						//         <p className="green success-message">
						//           Demande envoyée avec succès.
						//         </p>
						//       ) : this.state.sendingSuccess === false ? (
						//         <p className="error-message">
						//           <em>
						//             Une erreur s'est produite pendant l'envoi de votre
						//             demande. Merci de réessayer à nouveau ou de nous
						//             contacter directement à{" "}
						//             <a href="mailto:contact@selfcity.fr">
						//               contact@selfcity.fr
						//             </a>{" "}
						//             .
						//           </em>
						//         </p>
						//       ) : null}
						//     </form>
						//   </div> */}
						</div>
					)}{' '}
				</div>
			</section>
		)
	}
}
