import axios from "axios";
import store from "store2";
import CONSTANTS from "./constants";
export default class ContactHelper {
  static postWebForm(email, endpoint = "sendContactEmail") {
    const referrer = store.session(CONSTANTS.REFERRER_STORAGE_KEY);
    const referrerHistory = store.local(CONSTANTS.REFERRER_STORAGE_KEY);

    const hostname = process.env.GATSBY_API_HOSTNAME;
    return axios.post(hostname + "/" + endpoint, {
      ...email,
      metadata: {
        referrer,
        referrerHistory,
      },
    });
  }

  static postContactForm({
    firstName,
    lastName,
    email,
    phoneNumber,
    postalCode,
    description,
    availability,
  }) {
    return this.postWebForm(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        postalCode,
        description,
        availability,
      },
      "sendContactEmail"
    );
  }

  static postFormFromVisio({
    firstName,
    lastName,
    email,
    phoneNumber,
    postalCode,
    description,
    availability,
  }) {
    return this.postWebForm(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        postalCode,
        description,
        availability,
      },
      "sendOnlineFormEmail"
    );
  }

  static sendPartnerFormEmail({
    firstName,
    lastName,
    email,
    phoneNumber,
    postalCode,
    description,
    availability,
  }) {
    return this.postWebForm(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        postalCode,
        description,
        availability,
      },
      "sendPartnerFormEmail"
    );
  }
}
