import React from "react";

export default class extends React.Component {
  render() {
    return (
      <button
        className={
          "flex flex-grow-1 form-button " +
          this.props.classes +
          (this.props.reverse ? " form-button-reverse" : "")
        }
        data-label={this.props.label}
        onClick={this.props.onClick}
      >
        {this.props.text || "Formulaire"}
      </button>
    );
  }
}
